import React, { Component } from 'react';
import InfoCard from './InfoCard';
import { timelockAddress, getBalanceTreasury, getTokenNameAndSymbolTreasury, TokenAddressTreasury, boxContractAddress, graphTreasury, getTokenSymbolTreasury, blockExplorer } from './../open2web3';
import { useState } from 'react';
import { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import InfoToken from './InfoToken';
import MaterialTable from 'material-table';
import { forwardRef } from 'react';
import Web3 from 'web3';
 
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { SymbolTreasuryToken, treasuryBalance, TreasuryTokenInfo } from '../atom';
import { Button } from 'antd';
import { DownloadOutlined,EyeTwoTone  } from '@ant-design/icons';
import { ArrowsAltOutlined } from '@ant-design/icons';
import TreasuryTokenInfoComponent from './TreasuryTokenInfoComponent';
import DelegateVotes from './DelegateVotes';
 
 const  Finance = ()=> {
    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
      };

   const [treasuryBalanceState,setTreasuryBalanceState] = useRecoilState(treasuryBalance);
   const [treasuryTokenInfo,setTreasuryTokenInfo] = useState("");
   const [transfers,setTransfers] = useState();
   const [symbolTreasury,setSymbolTreasury] = useRecoilState(SymbolTreasuryToken);
   const web3 = new Web3(Web3.givenProvider);

   useEffect(() => {
    // Update the document title using the browser API
     getTreasuryBalance();
     getTreasuryTokenInfo();
     getTransfers();
     getTreasuryTokenSymbol();
      
  } ,[]);
     
   const getTreasuryBalance = async ()=>{

    const res = await getBalanceTreasury(boxContractAddress) ; 
    // console.log('ress' ,res);
   setTreasuryBalanceState(res);
   }

   const getTreasuryTokenInfo = async ()=>{

    const res  = await getTokenNameAndSymbolTreasury(TokenAddressTreasury);
    setTreasuryTokenInfo(res)
   }

   const getTreasuryTokenSymbol = async ()=>{
       const symbol = await getTokenSymbolTreasury();
      //  console.log('sym ',symbol);
       setSymbolTreasury(symbol);
   }

   const getTransfers = async ()=>{
    fetch(graphTreasury,{
        method: 'POST',
        body: JSON.stringify({
          query: `{transfers(orderBy : block ,orderDirection:desc) {
            id
            from
            to
            amount
            hash
          }}`
        })
    }).then( (response) => response.json())
    .then( json => setTransfers(json?.data?.transfers) );
    // setTransfers(json?.data?.transfers)
    }
    

    const ViewTransactionOnBlockExplorer=(hash)=>{
      window.open(blockExplorer+'/tx/'+hash,'_blank');
    }



    return (  
    <React.Fragment>
   <Container>
        <div><h1>Finance</h1></div>

        {/* <DelegateVotes/> */}
         
        <Row>
        <Col xs={12} md={12} lg={8}>
        <div className="cardInfo">
        <InfoCard title="CONTRACT" text={boxContractAddress} displayD="d-none"/>
<InfoCard title="BALANCE" text={treasuryBalanceState} displayD="d-none"/>
        </div>
        

</Col>
<Col  xs={12} md={12} lg={4}>
        <div className="InfoToken2">
        {/* <InfoToken title="TOKEN INFO"/> */}
        {/* <InfoToken title="Total supply" text={1}/> */}
        {/* <InfoToken title="Transferable" text="YES"/> */}
        {/* <InfoToken title="Token" text={treasuryTokenInfo}/> */}
        <TreasuryTokenInfoComponent treasuryTokenInfo={treasuryTokenInfo} contractAddress={TokenAddressTreasury}/>
        </div>
        {/* <div className="InfoToken">
        <div><h6 style={{color:'rgb(124, 153, 214)'}}>OWNERSHIP DISTRIBUTION</h6></div> 
        <div>Tokenholder stakes</div> 
        <Progress percent={90} />
        </div> */}
        </Col>
        </Row>


        <Row>
          <Col xs={12} md={12} lg={12}  className="tabTo">
        
         

          <MaterialTable
            options={{
              showTitle: false,
              search:false,
            }}
            icons={tableIcons}
              columns={[
                { title: 'to', field: 'to' },
                
                { title: 'Amount', field: 'amount', render: rowData => 
                <div className="alignView">
                {web3.utils.fromWei(rowData.amount,'ether') + ' '+ symbolTreasury} 
                 <img src="/iconLink.svg" onClick={()=>ViewTransactionOnBlockExplorer(rowData.hash)}/>
               
               
                
                 </div> }, 
                // {title:'' , field : 'hash',render :rowData=> <a href={blockExplorer+rowData.hash} target='_blank'>View in block Explorer</a>},
              //   {title:'' , field : 'hash',render :rowData=>  <Button   shape="round" icon={<EyeTwoTone  />} size={'small'} onClick={()=>ViewTransactionOnBlockExplorer(rowData.hash)}>
               
              // </Button>}
                // { title: 'hash', field: 'hash' }
              ]}
              data={transfers}
             
          />
          </Col>
        </Row>
        </Container>
        </React.Fragment>);



}

export default Finance;