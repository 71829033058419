
import Connection from './connection/Connection';
import Login from "./connection/Login";
import { useRecoilValue } from 'recoil';
import { connected } from "../atom";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';

function NavBar() {



    const connectedAddress = useRecoilValue(connected)
    return ( 

      <div>
      <Navbar collapseOnSelect expand="lg" variant="dark">
      <Container fluid>
        <Navbar.Brand><img src="/logoopen2be.png" width="130" className="img-fluid"/></Navbar.Brand>
        <Navbar.Toggle aria-controls='navbarScroll' className="tog" />
        <Navbar.Collapse id="navbarScroll">
        
          <Nav className="me-auto" navbarScroll>
          {connectedAddress!="" && 
            <>
            <Nav.Link  ><Link to="/tokens" className="nav-link">Tokens</Link></Nav.Link>
            <Nav.Link><Link to="/vote" className="nav-link">Vote</Link></Nav.Link>
            <Nav.Link ><Link to="/finance" className="nav-link">Finance</Link></Nav.Link>
            </>
           }
           
          </Nav>
          <Nav  className="centerBH">
            <Nav.Link href="#deets" className="small">  <small>{connectedAddress}</small></Nav.Link>
            <Nav.Link eventKey={2} href="#memes">
            <Login/>
            </Nav.Link>
          </Nav>
          
        </Navbar.Collapse>
        </Container>
    </Navbar>
     

    
      
   </div>
     );
}

export default NavBar;