import { ApolloClient, gql, InMemoryCache } from '@apollo/client';
import { Progress } from 'antd';
import { useRecoilState } from 'recoil';
import { totalSypply } from '../../atom';
import { graphAPIGov } from '../../open2web3';
import TopVoters from './TopVoters';
import { useState } from 'react';
import { useEffect } from 'react';
import VotersList from './VotersList';


function ForBox(props) {


    const client = new ApolloClient({
        uri: graphAPIGov,
        cache: new InMemoryCache(),
      });
    
    const [contractTotalSypply, setContractTotalSypply] = useRecoilState(totalSypply);
    const [topVoters , setTopVoters] = useState([]);
    const [openVotersList , setOpenVotersList] = useState(false);
    const getTopVoters = async () => {
        client
          .query({
            query: gql`{
                  votes( where : {proposalId: "${props.proposalId}" support:"1" } first:3 ){
                    id
                    proposalId
                    voter
                    support
                    weight
                    date
                  }
                }`,
          })
          .then((result) => setTopVoters(result.data.votes));
      };

      const showModal = () => {
        setOpenVotersList(true);
      };
    
      const handleOk = () => {
        setOpenVotersList(false);
      };
    
      const handleCancel = () => {
        setOpenVotersList(false);
      };
  

    
      useEffect(()=>{
        getTopVoters();
      },[])
      useEffect(()=>{
        // console.log("top voters : ",topVoters)
      },[topVoters])

      const topVotersList = topVoters.map((voter)=>{
        <TopVoters key={voter.voter} address={voter.voter} weight={voter.weight}/>
      })
    return (  <>
    <div className="proposalCardG voteBg">
   
        <div className="proposalText"><h6>Yes</h6><div>{props.votes}</div></div> 


      
    <Progress percent={((props.votes/ props.pastTotalSupply) *100 ).toFixed(2)}/>

    <div className="tokenInfoD adV"><div>Addresses  {props.count && props.count}</div>
   <div>votes</div> 
    </div>
   
      {
         topVoters.map((voter) =>
         <TopVoters key={voter.voter} address={voter.voter} weight={voter.weight} voteState={"1"} voteTitle={"YES"} proposalId={props.proposalId}/>
       )
      }

{props.count>0 && <a onClick={showModal} className="viewS">View List</a>}

<VotersList open={openVotersList} handleOk={handleOk} handleCancel={handleCancel} voteState={"1"} proposalId={props.proposalId} voteTitle={"YES"} total={props.count}/>
    
    </div>
    </>);
}

export default ForBox;