import { ApolloClient, gql, InMemoryCache } from "@apollo/client";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import Web3 from "web3";
import { totalSypply } from "../atom";
import { getProposalState, graphAPIGov, proposals } from "../open2web3";
import AbstainBox from "./proposalDetails/AbstainBox";
import AgainstBox from "./proposalDetails/AgainstBox";
import DetailsBox from "./proposalDetails/DetailsBox";
import ForBox from "./proposalDetails/ForBox";
import HistoryBox from "./proposalDetails/HistoryBox";
import TotalSupplyComponent from "./TotalSupplyComponent";
import { Tag } from 'antd';
// import { totalSypply } from './../atom';

function ProposalDetails(props) {
  const web3 = new Web3(Web3.givenProvider);
  const client = new ApolloClient({
    uri: graphAPIGov,
    cache: new InMemoryCache(),
  });

  const proposalId = useParams("proposalId");
  const [proposalDetails, setProposalDetails] = useState(null);
  const [proposal, setProposal] = useState({});
  const [idProposal, setIdProposal] = useState(null);
  const [creationDate, setCreationDate]=useState(null)
  const [prposalState, setProposalState] = useState(null);
  const [prposalStateColor, setPrposalStateColor] = useState(null);

  const getProposalDetails = async () => {
    const res = await proposals(proposalId.proposalId);
    //   debugger;
    setProposalDetails(res);
    // console.log(res);
    // setProposalDetails(res);
  };


  const getProposal = async () => {
    client
      .query({
        query: gql`{
              prposals( where : {proposalId: "${proposalId.proposalId}"}){
                id
                proposalId
                prposer
                startBlock
                description
                countFor
                countAgainst
                countAbstain
                creationTimeStamp
                totalSupply
              }
            }`,
      })
      .then((result) => setProposal(result.data.prposals[0]));
  };

  const getPropsalStateHandler = async () => {
    const res = await getProposalState(proposalId.proposalId);

    switch (parseInt(res)) {
      case 0:
        setProposalState("Pending");
        setPrposalStateColor("purple");
        break;
      case 1:
        setProposalState("Active");
        setPrposalStateColor("blue");
        break;
      case 2:
        setProposalState("Canceled");
        setPrposalStateColor("red");
        break;
      case 3:
        setProposalState("Failed");
        setPrposalStateColor("red");
        break;
      case 4:
        setProposalState("Passed");
        setPrposalStateColor("green");
        break;
      case 5:
        setProposalState("Queued");
        setPrposalStateColor("green");
        break;
      case 6:
        setProposalState("Expired");
        setPrposalStateColor("magenta");
        break;
      case 7:
        setProposalState("Executed");
        setPrposalStateColor("green");
        break;
    }
  };
  useEffect(() => {
    if (proposalId.proposalId != null) {
      // console.log(proposalId.proposalId);
      setIdProposal(proposalId.proposalId);
      getProposalDetails();
      getProposal();
      getPropsalStateHandler();
    }
  }, [proposalId.proposalId]);

  useEffect(() => {
    // console.log(proposalDetails);
  }, [proposalDetails]);

  useEffect(() => {
    // console.log("current proposal ", proposal);
    console.log("createdddd : ",proposal.creationTimeStamp)
    if(proposal.creationTimeStamp==undefined)
    return

    let date =new Date(proposal.creationTimeStamp * 1000)
    // let dateFormat = date.getHours() + ":" + date.getMinutes() + ", "+ date.toDateString();
    let dateFormat =date.toLocaleDateString("default") +" - "+ date.toLocaleTimeString("default"); 
   setCreationDate(dateFormat);
  }, [proposal]);

  return (
    <>
    <TotalSupplyComponent/>
      <div className="proposalT">
        <h4>{proposal && proposal.description} <span><Tag color={prposalStateColor}>{prposalState}</Tag></span>
          </h4>
        

        <div className="createBy">
          <div> <div>created by</div>
          <small>{proposal && proposal.prposer}</small></div>
         <div><small>{creationDate!=null && creationDate}</small></div>
          

        </div>
        
      </div>
     

      <div className="proposalCard">
        <ForBox
          votes={web3.utils.fromWei(
            proposalDetails != null ? proposalDetails.forVotes : "0",
            "ether"
          )}
          proposalId={proposalId.proposalId}
          count = {proposal.countFor}
          pastTotalSupply={proposal.totalSupply}
        />

        <AgainstBox
           
          votes={web3.utils.fromWei(
            proposalDetails != null ? proposalDetails.againstVotes : "0",
            "ether"
          )}
          proposalId={proposalId.proposalId}
          count = {proposal.countAgainst}
          pastTotalSupply={proposal.totalSupply}
        />
        <AbstainBox votes={web3.utils.fromWei(
            proposalDetails != null ? proposalDetails.abstainVotes : "0",
            "ether"
          )} 
          proposalId={proposalId.proposalId}
          count = {proposal.countAbstain}
          pastTotalSupply={proposal.totalSupply}
          />
      </div>
      <div className="proposalCardL2">
        <DetailsBox proposalId={proposalId.proposalId} />
        <HistoryBox />
      </div>
    </>
  );
}

export default ProposalDetails;
