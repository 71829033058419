import { Button, Progress } from "antd";
import React, { Component, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  balance,
  connected,
  totalSypply,
  nameAndSymbol,
  SymbolGovToken,
} from "../atom";
import {
  getBalance,
  getTokenNameAndSymbol,
  getTokenSymbol,
  getTotalSupply,
  graphAPIToken,
  TokenAddress,
} from "../open2web3";
import InfoCard from "./InfoCard";
import InfoToken from "./InfoToken";
import { useEffect } from "react";
import MaterialTable from "material-table";
import { forwardRef } from "react";
import Web3 from "web3";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import TotalSupplyComponent from "./TotalSupplyComponent";
import DelegateVotes from './DelegateVotes';

function Tokens(props) {
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const [connectedAddress, setConnectedAddress] = useRecoilState(connected);
  const [balanceConnectedUser, setBalanceConnectedUser] =
    useRecoilState(balance);
  const contractTotalSypply = useRecoilValue(totalSypply);
  const [tokenNameAndSymbol, setTokenNameAndSymbol] =
    useRecoilState(nameAndSymbol);
  const [symbolGovToken, setSymbolGovToken] = useRecoilState(SymbolGovToken);
  const [accounts, setAccounts] = useState();
  const web3 = new Web3(Web3.givenProvider);

  useEffect(() => {
    getConnectedUserBalance(connectedAddress);

    getTokenSymbolHandler();
    getTokenSymnolHandler();
  }, []);

  useEffect(() => {
    getSharehorlders();
  }, []);

  // useEffect( () => console.log(accounts),[accounts]);

  const getConnectedUserBalance = async (connectedAddress) => {
    try {
      const bal = await getBalance(connectedAddress);
      setBalanceConnectedUser(bal);
    } catch (err) {
      console.log(err);
    }
  };

  const getSharehorlders = () => {
    fetch(graphAPIToken, {
      method: "POST",
      body: JSON.stringify({
        query: `{
              accounts(first: 10 ,orderBy:balances , orderDirection:desc, where:{balances_gt :0}) {
                id
                address
                balances
              }
            }`,
      }),
    })
      .then((response) => response.json())
      .then((json) => setAccounts(json?.data?.accounts));
  };

  const getTokenSymbolHandler = async () => {
    try {
      const nameAndSymbol = await getTokenNameAndSymbol();
      setTokenNameAndSymbol(nameAndSymbol);
      // console.log('symbol ', nameAndSymbol);
    } catch (err) {
      console.log(err);
    }
  };

  const getTokenSymnolHandler = async () => {
    const symbol = await getTokenSymbol();
    setSymbolGovToken(symbol);
  };

  const i = 1;
  return (
    <React.Fragment>
      <Container>
        <TotalSupplyComponent />

        {connectedAddress != "" ? (
          <div className="addToken">
            <div></div>
            {/* <div><Button type="primary" size={20}>Add Tokens</Button></div> */}
          </div>
        ) : (
          <h1>Tokens</h1>
        )}

        {connectedAddress != "" ? (
          <Row>
            <Col xs={12} md={12} lg={8}>
              <h1>Tokens</h1>

              <div className="cardInfo">
                <InfoCard
                  title="WALLET"
                  text={connectedAddress}
                  displayD="d-none"
                />
                <InfoCard
                  title="BALANCE"
                  text={balanceConnectedUser + " " + symbolGovToken}
                  displayD="d-none"
                />
              </div>
              {/* <div className="cardInfo">

<InfoCard title="WALLET" text={connectedAddress} displayD="d-none"/>


<InfoCard title="BALANCE" text={balanceConnectedUser}/>
</div> */}
            </Col>

            <Col xs={12} md={12} lg={4}>
              <h1>Token info</h1>
              <div className="InfoToken2">
                {/*  <InfoToken title="TOKEN INFO" />*/}
                {/* <InfoToken title="Total supply" text={contractTotalSypply}/>
        <InfoToken title="Transferable" text="YES"/>
        <InfoToken title="Token" text={tokenNameAndSymbol}/> */}
                <InfoToken
                  contractTotalSypply={contractTotalSypply}
                  transferable={"YES"}
                  tokenNameAndSymbol={tokenNameAndSymbol}
                  contractAddress={TokenAddress}
                />
                {/* <DelegateVotes/> */}
              </div>
              {/* <div className="InfoToken">
        <div><h6 style={{color:'rgb(124, 153, 214)'}}>OWNERSHIP DISTRIBUTION</h6></div> 
        <div>Tokenholder stakes</div> 
        <Progress percent={90} />
        </div> */}
            </Col>
          </Row>
        ) : null}
        {connectedAddress != "" ? (
          <div>
            <h1 style={{ paddingBottom: 15 }}>Holders</h1>
            <Row>
              <Col xs={12} md={12} lg={12} className="tabTo">
                <MaterialTable
                  options={{
                    showTitle: false,
                    search: false,
                  }}
                  icons={tableIcons}
                  columns={[
                    { title: "Address", field: "address" },
                    {
                      title: "Balance",
                      field: "balances",
                      render: (rowData) =>
                        web3.utils.fromWei(rowData.balances, "ether") +
                        " " +
                        symbolGovToken,
                    },
                  ]}
                  data={accounts}
                />
              </Col>
            </Row>{" "}
          </div>
        ) : null}
      </Container>
    </React.Fragment>
  );
}

function s() {
  return 1;
}

export default Tokens;
